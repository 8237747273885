// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export const EventLogNames = {
  AbandonedChatAutomation: 'AbandonedChatAutomation',
  AgentEvent: 'AgentEvent',
  CallEvent: 'CallEvent',
  ChatEvent: 'ChatEvent',
  ErrorEvent: 'ErrorEvent',
  RenderEvent: 'RenderMeasurementEvent'
};

export const AbandonedChatOperations = {
  Pinned: 'ChatPinned',
  Unpinned: 'ChatUnpinned',
  Inactive: 'Inactive',
  Warning: 'Warning',
  AutoClosing: 'AutoClosing',
  Closed: 'Closed',
  Active: 'Active'
};

export const AgentOperations = {
  ApplicationStopped: 'ApplicationStopped',
  Echo : 'Echo',
  EchoEventHub : 'EchoEventHub',
  EchoCallback : 'EchoCallback',
  ConnectionPoor : 'ConnectionPoor',
  LogIn: 'LogIn',
  LogOut: 'LogOut',
  WindowUnload : 'WindowUnload',
  WindowLoad : 'WindowLoad',
  Disconnect : 'Disconnect',
  Reconnected : 'Reconnected',
  Reconnecting : 'Reconnecting',
  SessionDisconnected : 'SessionDisconnected',
  UnauthorizedVerifySession: 'UnauthorizedVerifySession',
  SessionVerified: 'SessionVerified',
  UnauthorizedLogOut: 'UnauthorizedLogOut',
  PageLoadComplete: 'PageLoadComplete',
  AdvancedLoginPageLoad: 'AdvancedLoginPageLoad',
  AdvancedLoginSubmit: 'AdvancedLoginSubmit',
  AdvancedLoginCancel: 'AdvancedLoginCancel',
  ChangeStatus: 'ChangeStatus',
  SessionLockoutWarning: 'SessionLockoutWarning',
  SessionLockoutWarningCancel: 'SessionLockoutWarningCancel',
  SessionLockoutTransfer: 'SessionLockoutTransfer',
  SessionLockoutClose: 'SessionLockoutClose',
  SessionLockoutPageLoad: 'SessionLockoutPageLoad',
  SessionLockoutReAuthenticate: 'SessionLockoutReAuthenticate',
  SessionLockoutLogout: 'SessionLockoutLogout',
  CtiConnectionClosed: 'CtiConnectionClosed',
  RefreshAnalytics: 'RefreshAnalytics',
  PromptSurvey: 'PromptSurvey',
  DismissSurvey: 'DismissSurvey',
  TakeSurvey: 'TakeSurvey',
  CreateCustomHeader: 'CreateCustomHeader',
  CreateCustomResponse: 'CreateCustomResponse',
  ManageCustomResponses: 'ManageCustomResponses',
  SaveCustomScripts: 'SaveCustomScripts',
  CallNotFound: 'CallNotFound',
  ChatNotFound: 'ChatNotFound',
  AcceptChatFailed: 'AcceptChatFailed',
  ChatAcceptanceUnknown: 'ChatAcceptanceUnknown',
  ServerSync: 'ServerSync',
  StatusSync: 'StatusSync',
  MySupportOpened: 'MySupportOpened',
  ChangeVolume: 'ChangeVolume',
  MuteVolume: 'MuteVolume',
  UnmuteVolume: 'UnmuteVolume',
  TestVolume: 'TestVolume',
  OutboundLinkClick: 'OutboundLinkClick',
  ToggleExtraChats: 'ToggleExtraChats',
  ChangeScreen: 'ChangeScreen',
  ServerSyncResult: 'ServerSyncResult',
  DisconnectTimeout: 'DisconnectTimeout',
  QualityCheckOffer: 'QualityCheckOffer',
  QualityCheckResponse: 'QualityCheckResponse',
  QualityCheckFollowupOffer: 'QualityCheckFollowupOffer',
  QualityCheckFollowupResponse: 'QualityCheckFollowupResponse',
  DismissNewFeatureBanner: 'DismissNewFeatureBanner',
  ViewNewFeatureDetails: 'ViewNewFeatureDetails',
  OpenSettingsPanel: 'OpenSettingsPanel',
  NavSettingsTab: 'NavSettingsTab',
  SaveSettings: 'SaveSettings',
  ChatRequestProcessable: 'ChatRequestProcessable',
  ShowLiveTranscript: 'ShowLiveTranscript',
  HideAgentTranscript: 'HideAgentTranscript',
  FloatingToggleClicked: 'FloatingToggleClicked',  
  PreviousChatsummaryCopied: 'PreviousChatsummaryCopied',
  PreviousSummariesClicked: 'PreviousSummariesClicked',
  UnusedSlotTimes: 'UnusedSlotTimes',
  FeedbackResponse: 'FeedbackResponse',
  KnowledgeFocused: 'KnowledgeFocused',
  KnowledgeSearchSent: 'KnowledgeSearchSent',
  KnowledgeResponse: 'KnowledgeResponse',
  KnowledgeSourceClicked: 'KnowledgeSourceClicked',
  KnowledgeFeedback: 'KnowledgeFeedback',
  KnowledgeFeedbackFollowup: 'KnowledgeFeedbackFollowup',
  KnowledgeError: 'KnowledgeError',
  TranslationToggled: 'TranslationToggled'
};

export const ChatOperations = {
  ApplicationFocus: 'ApplicationFocus',
  NewEngagement: 'NewEngagement',
  NewEngagementAcceptChatAPICall: 'NewEngagementAcceptChatAPICall',
  NewEngagementAcceptChatAPICallResponse: 'NewEngagementAcceptChatAPICallResponse',
  ChatLoadSuccess: 'ChatLoadSuccess',
  ChannelInfoUpdate: 'ChannelInfoUpdate',
  LoadAsyncPreviousEngagements: 'LoadAsyncPreviousEngagements',
  LinkAccount: 'LinkAccount',
  CloseEngagement: 'CloseEngagement',
  JumpToEngagement: 'JumpToEngagement',
  JumpToLatest: 'JumpToLatest',
  PromptCloseEngagementExit: 'PromptCloseEngagementExit',
  PromptCloseEngagement: 'PromptCloseEngagement',
  transferChat: 'transferChat',
  transferFailed: 'TransferFailed',
  bounceChat: 'bounceChat',
  bounceFailed: 'bounceFailed',
  customerDisconnect: 'CustomerDisconnect',
  customerReconnect: 'CustomerReconnect',
  ViewHistoricTranscript: 'ViewHistoricTranscript',
  InformationDeliveryRequest: 'InformationDeliveryRequest',
  InformationDeliveryResponse: 'InformationDeliveryResponse',
  InformationDeliveryBeginCallResponse: 'InformationDeliveryBeginCallResponse',
  InformationDeliveryReconnect: 'InformationDeliveryReconnect',
  MaskText: 'MaskText',
  CopyText: 'CopyText',
  CopyChatId: 'CopyChatId',
  NewSuggestion: 'NewSuggestion',
  SendSuggestion: 'SendSuggestion',
  DismissSuggestion: 'DismissSuggestion',
  CustomerButtonResponse: 'CustomerButtonResponse',
  ChatLost: 'ChatLost',
  JoinChatFailed: 'JoinChatFailed',
  AgentFirstResponse: 'AgentFirstResponse',
  AgentInactive: 'AgentInactive',
  ChatFocus: 'ChatFocus',
  ReconnectEngagement: 'ReconnectEngagement',
  ConversationCardMenu: 'ConversationCardMenu',
  DispositionSelect: 'DispositionSelect',
  PinMessage: 'PinMessage',
  UnpinMessage: 'UnpinMessage',
  ClearMessagePins: 'ClearMessagePins',
  ExpandMessagePins: 'ExpandMessagePins',
  JumpToMessage: 'JumpToMessage',
  LoadPriorEngagements: 'LoadPriorEngagements',
  RefuseEngagement: 'RefuseEngagement',
  RefuseEngagementSuccess: 'RefuseEngagementSuccess',
  RefuseEngagementFailed: 'RefuseEngagementFailed',
  ChatUnfocus: 'ChatUnfocus',
  RetrySendMessage: 'RetrySendMessage',
  AbortSendMessage: 'AbortSendMessage',
  ScriptAutocomplete: 'ScriptAutocomplete',
  ChatStartGreeting: 'ChatStartGreeting',
  SendChatStartGreeting: 'SendChatStartGreeting',
  DismissChatStartGreeting: 'DismissChatStartGreeting',
  NewExternalSuggestion: 'NewExternalSuggestion',
  SendExternalSuggestion: 'SendExternalSuggestion',
  DetectItg: 'DetectItg',
  NewItgSuggestion: 'NewItgSuggestion',
  DismissItgSuggestion: 'DismissItgSuggestion',
  StartItg: 'StartItg',
  PauseItg: 'PauseItg',
  ResumeItg: 'ResumeItg',
  CancelItg: 'CancelItg',
  CompleteItg: 'CompleteItg',
  ViewDetailsPanel: 'ViewDetailsPanel',
  SecureDataRequestStart: 'SecureDataRequestStart',
  SecureDataRequestReceivedLink: 'SecureDataRequestReceivedLink',
  SecureDataRequestSendLink: 'SecureDataRequestSendLink',
  SecureDataRequestCancel: 'SecureDataRequestCancel',
  XaCardOffers: 'XaCardOffers',
  CloseEngagementInstruction: 'CloseEngagementInstruction',
  ShowPlaceholderForm: 'ShowPlaceholderForm',
  SavePlaceholder: 'SavePlaceholder',
  SmartResponsesNoResults: 'SmartResponseNoResults',
  SendSmartResponse: 'SendSmartResponse',
  SelectMessage: 'SelectMessage',
  TransferAgentOptionsEmpty: 'TransferAgentOptionsEmpty',
  TransferOptionsEmpty: 'TransferOptionsEmpty',
  SendItgPrimer: 'SendItgPrimer',
  SkipItgPrimer: 'SkipItgPrimer',
  SuggestionBreadcrumb: 'SuggestionBreadcrumb',
  NewSchedulerSuggestion: 'NewSchedulerSuggestion',
  StartScheduler: 'StartScheduler',
  CompleteScheduler: 'CompleteScheduler',
  PauseScheduler: 'PauseScheduler',
  ResumeScheduler: 'ResumeScheduler',
  ReceiveInvoluntaryTransfer: 'ReceiveInvoluntaryTransfer',
  AutoRetryMessageFailed: 'AutoRetryMessageFailed',
  AutoRetryMessageSuccess: 'AutoRetryMessageSuccess',
  DismissSuggestionFeedback: 'DismissSuggestionFeedback',
  DismissSuggestionFeedbackDismiss: 'DismissSuggestionFeedbackDismiss',
  RemoveSuggestion: 'RemoveSuggestion',
  RefreshTransferOptions: 'RefreshTransferOptions',
  SendAutoGreeting: 'SendAutoGreeting',
  CustomerLeftChat: 'CustomerLeftChat',
  GptResponseSent: 'GptResponseSent',
  GptResponseSentToChatBox: 'GptResponseSentToChatBox',
  GptResponseFailed: 'GptResponseFailed',
  GptResponseFeedback: 'GptResponseFeedback',
  GptResponseOffered: 'GptResponseOffered',
  ChatSummaryFeedback: 'ChatSummaryFeedback',
  ChatNotesFeedback: 'ChatNotesFeedback',
  ChatSummaryCopied: 'ChatSummaryCopied',
  ChatSummary: 'ChatSummary',
  NotesCopied: 'NotesCopied',
  ChatSummarySaved: 'ChatSummarySaved',
  CustomerAwaitingReplyNotificationClicked: 'CustomerAwaitingReplyNotificationClicked',
  AddCustomerAwaitingReplySuggestion: 'AddCustomerAwaitingReplySuggestion',
  SendCustomerAwaitingReplySuggestion: 'SendCustomerAwaitingReplySuggestion',
  AsyncEngagementsSummaryFeedback: 'AsyncEngagementsSummaryFeedback',
  PauseAbandonedChat: 'PauseAbandonedChat',
  ChatClosedInQueue: 'ChatClosedInQueue',
  TranslationRequest: 'TranslationRequest',
  AsyncTimeOutInfo: 'AsyncTimeOutInfo',
  AskMeAnythingRewriteClicked: 'AskMeAnythingRewriteClicked',
  AskMeAnythingRewriteModified: 'AskMeAnythingRewriteModified',
  MessageEditorUndoClicked: 'MessageEditorUndoClicked',
  EnhanceRequested: 'EnhanceRequested',
  EnhanceResponseOffered: 'EnhanceResponseOffered',
  EnhanceResponseSent: 'EnhanceResponseSent',
  TranslationToggle: 'TranslationToggle',

  //Errors
  ChatSummaryError: 'ChatSummaryError',
  SchedulerError: 'SchedulerError',
  ItgError: 'ItgError',
  SaveChatSummaryError: 'SaveChatSummaryError',
  TranslationError: 'TranslationError',
  AskMeAnythingRewriteError: 'AskMeAnythingRewriteError',
  EnhanceResponseFailed: 'EnhanceResponseFailed'
};

export const CallOperations = {
  InformationDeliveryRequest: 'InformationDeliveryRequest',
  InformationDeliveryResponse: 'InformationDeliveryResponse',
  CallStart: 'CallStart',
  CallEnd: 'CallEnd',
  CloseSession: 'CloseSession',
  HighlightTranscriptEntry: 'HighlightTranscriptEntry',
  SuggestionOffered: 'SuggestionOffered',
  ITGLaunched: 'ITGLaunched',
  ITGFeedback: 'ITGFeedback',
  ITGNotificationClicked: 'ITGNotificationClicked',
  CallSummary: 'CallSummary',
  SummaryCopied: 'SummaryCopied',
  CallIDCopied: 'CallIDCopied',
  SummaryFeedback: 'SummaryFeedback',
  CollapseSummary: 'CollapseSummary',
  CancelSummaryModal: 'CancelSummaryModal',
  RetryGetCallSummary: 'RetryGetCallSummary',
  JumpedToLatest: 'JumpedToLatest',
  FirstTranscriptMessage: 'FirstTranscriptMessage',
  WrapCall: 'WrapCall',
  EndSession: 'EndSession',

  // Errors
  CallSummaryError: 'CallSummaryError',
  CallLaunchItgError: 'CallLaunchItgError'
};

export const DebugEvents = {
  GetUpdatesPrevented: 'GetUpdatesPrevented',
  StartGetUpdates: 'StartGetUpdates',
  StopGetUpdates: 'StopGetUpdates',
  SmartResponseFeedback: 'SmartResponseFeedback',
  OfferQualityCheck: 'OfferQualityCheck',
  QualityCheckDateTimeLoaded: 'QualityCheckDateTimeLoaded',
  QualityCheckDateTimeUpdated: 'QualityCheckDateTimeUpdated'
};

export const ErrorEvents = {
  ChatDialogAppUpdateError: 'ChatDialogAppUpdateError',
  ChangeStatusError: 'ChangeStatusError',
  CtiConnectionError: 'CtiConnectionError',
  PageLoadError: 'PageLoadError',
  SecureDataRequestError: 'SecureDataRequestError',
  VerifySessionGetTokenError: 'VerifySessionGetTokenError'
};

export const ChatGreetingEvents = {
  FirstAsync: 'asyncNew',
  WebReconnect: 'webReconnect',
  Transfer: 'transfer',
  ReturningAsync: 'asyncContinued'
};

export const autoCloseReasons = {
  HeartbeatExpired: 'heartbeatExpired',
  CustomerClosedChat: 'customerClosedChat'
};