// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

export class FeatureFlags {
  public static readonly AbandonedChatAutomation: string = 'AbandonedChatAutomation';
  public static readonly ApplicationFocus: string = 'ApplicationFocus';
  public static readonly CxGpt: string = 'CxGpt';
  public static readonly LazyLoadPriorEngagements: string = 'LazyLoadPriorEngagements';
  public static readonly LogDebug: string = 'LogDebug';
  public static readonly MessagePinning: string = 'MessagePinning';
  public static readonly MySupportOutboundLinks: string = 'MySupportOutboundLinks';
  public static readonly NpsSurvey: string = 'NpsSurvey';
  public static readonly XaResponse: string = 'XaResponse';
  public static readonly XaSolves: string = 'XaSolves';
  public static readonly ScriptAutocomplete: string = 'ScriptAutocomplete';
  public static readonly HasNewSettings: string = 'HasNewSettings';
  public static readonly HasAgentSummary: string = 'HasAgentSummary';
  public static readonly SecureDataCollection: string = 'SecureDataCollection';
  public static readonly SmartResponses: string = 'SmartResponses';
  public static readonly SmartResponsesAppliedAi: string = 'SmartResponsesAppliedAi';
  public static readonly QualityCheck: string = 'QualityCheck';
  public static readonly EinsteinChat: string = 'EinsteinChat';
  public static readonly AgentSalesConversionStats: string = 'AgentSalesConversionStats';
  public static readonly FeatureBanner: string = 'FeatureBanner';
  public static readonly LinkEditorTarget: string = 'LinkEditorTarget';
  public static readonly AutoGreeting: string = 'AutoGreeting';
  public static readonly VoiceAgent: string = 'VoiceAgent';
  public static readonly TransferToAgent: string = 'TransferToAgent';
  public static readonly ChatConversationSummary: string = 'ChatConversationSummary';
  public static readonly HideSummary: string = 'HideSummary';
  public static readonly MercuryReact: string = 'MercuryReact';
  public static readonly UnifiedNotes: string = 'UnifiedNotes';
  public static readonly DisconnectPreventionMessage: string = 'DisconnectPreventionMessage';
  public static readonly AskMeAnything: string = 'AskMeAnything';
  public static readonly AskMeAnythingRewrite: string = 'AskMeAnythingRewrite';
  public static readonly LanguageTranslator: string = 'LanguageTranslator';
  public static readonly UseNarrative: string = 'UseNarrative';
  public static readonly UseRecap: string = 'UseRecap';
  public static readonly UseResolution: string = 'UseResolution';
  public static readonly EnhanceMessages: string = 'EnhanceMessages';
  // Will not work unless EnhanceMessages is enabled
  public static readonly AutoEnhanceMessages: string = 'AutoEnhanceMessages';
  public static readonly HideSmartResponses: string = 'HideSmartResponses';
  public static readonly PreventAsyncInactiveReset: string = 'PreventAsyncInactiveReset';
  public static readonly SendTimelineTranscript: string = 'SendTimelineTranscript';
  public static readonly ShowBotConversationSummary: string = 'ShowBotConversationSummary';
}
