// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Chat } from '../domain/models/chat';
import { Color } from '../domain/models/color';
import { Customer } from '../domain/models/customer';
import { AvailabilityType, ChannelType, ChatMessageStatus, ChatMessageType, ChatState, CustomerActivityStatus, LoadingState, SenderType, SuggestionType, SystemInfoMessageType, SystemMessageType, TransferType, Language, SendTaskType, BrowserVariationLanguage } from '../domain/models/enums';
import { NewChatResponse, TransferInResponse } from '../domain/models/responses/new-chat-response';
import { ChatMember } from '../domain/models/chatMember';
import { Channel } from '../domain/models/channel';
import { SmsDeviceInformation } from '../domain/models/smsDeviceInformation';
import { TransferredSuggestion, Suggestion, SuggestionBase } from '../domain/models/suggestion';
import { ChatInteraction } from '../domain/models/chatInteraction';
import { PriorEngagementParticipant } from '../domain/models/priorEngagement';
import { AsyncEngagementTranscript, ChatTranscriptMessage } from '../domain/models/chatTranscript';
import { InteractionTypes } from '../constants/interactionTypes.constants';
import { TransferSummaryConstants } from '../constants/transfer-summary.constants';
import { SystemChatMessage } from '../domain/models/systemChatMessage';
import { GetEngagementsResponse } from '../domain/models/responses/getEngagementsResponse';
import { ChatTranscriptAddressCalendarSelectionInteraction, ChatTranscriptAddressUpdateInteraction, ChatTranscriptResponseCustomerDisconnectedInteraction, ChatTranscriptResponseCustomerReconnectedInteraction, ChatTranscriptResponseDatapassInteraction, ChatTranscriptResponseImageMessageInteraction, ChatTranscriptResponseInformationMessageInteraction, ChatTranscriptResponseInteraction, ChatTranscriptResponseMediaCardInteraction, ChatTranscriptResponseMessageInteraction, ChatTranscriptResponsePageNavigatedInteraction, ChatTranscriptResponseParticipantExitInteraction, ChatTranscriptResponseTextMessageInteraction, ChatTranscriptResponseTopicSelectionInteraction, ChatTranscriptResponseTransferInformationInteraction, ChatTranscriptResponseXaSuggestionInteraction } from '../domain/models/responses/chat-transcript-response-interaction';
import { ChatMessage } from '../domain/models/chatMessage';
import { ChatImageMessage } from '../domain/models/chatImageMessage';
import { ChatParticipant } from '../domain/models/responses/chat-transcript-response';
import { AddXaSuggestions, AvailabilityChange } from '../domain/models';
import { ItgSuggestion } from '../domain/models/itgSuggestion';
import { SchedulerSuggestion } from '../domain/models/schedulerSuggestion';
import { DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { SystemMessages } from '../constants/systemMessages.constants';
import { ItgIntents, SchedulerIntents } from '../constants/itg-configuration.constants';
import { ItgMetadata } from '../domain/models/itgMetadata';
import { DriftContent } from '../domain/models/drift-content';
import { AgentFeatureFlags } from '../domain/models/agentFeatureFlags';
import { Constants, PageNames, SuggestionTitles } from '../constants/constants';
import { AutoGreetMessageConstants } from '../constants/auto-greet-messages.constants';
import { StringUtils } from 'src/app/utils/string-helper';
import { UuiHelper } from './uui-helper';
import { TimestampHelper } from './timestamp-helper';
import { DisconnectPreventionMessageConstants } from '../constants/disconnect-prevention-message-constants';
import { ChatSummaryData } from '../domain/models/chatSummaryData';
import { AppSettings } from 'src/app/constants/constants';
import { AgentGroupIdListsByLanguage } from '../domain/models/languageTranslationSettings';
import { CustomerDetails } from '../domain/models/customerDetails';
import { FeatureFlags } from '../constants/featureFlags.constants';

export class ChatHelper {
    static getCustomerUser(chat: Chat): Customer {
      return ChatHelper.getCustomerUserFromUsers(chat?.users);
    }

    static getCustomerUserFromUsers(users: ChatMember[]): Customer{
      let customer: Customer = null;
      if (users?.length > 0) {
          customer = <Customer>users.find(u => u.type === SenderType.Requester);
      }
      return customer;
    }

    static getCustomerName(displayName: string, firstName: string, lastName: string): string
    {
        let val: string;
        if (displayName && displayName !== 'You')
        {
            val = displayName;
        }
        else if (firstName) {
            val = `${firstName} ${lastName}`;
        }
        else {
            val = 'Customer ';
        }
        return val;
    }

    static isSystemMessage(chatMessage: ChatInteraction): boolean {
      return chatMessage.sender === SenderType.System
      || chatMessage.type === ChatMessageType.AddressUpdate
      || chatMessage.type === ChatMessageType.CalendarSelection
      || chatMessage.type === ChatMessageType.TopicSelection
      || chatMessage.type === ChatMessageType.ItgAction;
    }

    static isDataPass(chatMessage: ChatInteraction): boolean {
      return chatMessage.systemType === SystemMessageType.Datapass;
    }

    static isNavigation(chatMessage: ChatInteraction): boolean {
      return chatMessage.systemType === SystemMessageType.Navigation;
    }

    static isItgAction(chatMessage: ChatInteraction): boolean {
      return chatMessage.type === ChatMessageType.ItgAction;
    }

    static isXaMessage(chatMessage: ChatInteraction): boolean {
      return chatMessage.type === ChatMessageType.AddressUpdate
            || chatMessage.type === ChatMessageType.CalendarSelection
            || chatMessage.type === ChatMessageType.TopicSelection;
    }

    static isMessagePinned(pinnedMessageIds: string[], messageId: string): boolean {
      return Boolean(messageId && pinnedMessageIds?.includes(messageId));
    }

    static showMessagePin(parentChatId: string, chatId: string, status: ChatMessageStatus): boolean {
      // checks that the message is not a historic chat message
      const isCurrentChat = parentChatId === chatId;
      // do not show the pin if the status is pending/error/failed/retrying
      const successStatus = this.isMessageSent(status);
      return isCurrentChat && successStatus;
    }

    static isMaskedMessage(message: ChatInteraction): boolean{
      return Boolean(message.type === ChatMessageType.Message &&
          (<ChatMessage>message).maskedChunks?.length > 0);
    }

    static isMessageSent(status: ChatMessageStatus): boolean{
      // status only exists on AgentChatMessage, if it doesn't exist it is in the success state (i.e. customer messages)
      return !status || status === ChatMessageStatus.Sent;
    }

    static sortMessagesByTimestamp(messages: ChatInteraction[]): void{
      messages.sort((a, b) => {
        if (a.timestamp === b.timestamp) {
          return b.systemType === undefined ? 1 : -1;
        }
        return a.timestamp - b.timestamp;
      });
    }

    static mergeChatMessagesWithServerResponse(serverMessages: ChatInteraction[], chatMessages: ChatInteraction[]): ChatInteraction[] {
      let messages: ChatInteraction[] = [...chatMessages];

      if (serverMessages?.length) {
        // messages from the server that the agent doesn't currently have
        const missedMessages = serverMessages.filter(i => !chatMessages.find(m => m.messageId && m.messageId === i.messageId));
        messages = [...messages, ...missedMessages];

        this.sortMessagesByTimestamp(messages);
      }

      return messages;
    }

    static syncUsersFromTranscriptResponse(transcriptUsers: ChatParticipant[], chatUsers: ChatMember[]): ChatMember[]{
      let users = [ ...chatUsers ];
      transcriptUsers.forEach(p => {
        const userMatch = users.find(user => (user.id && p.id && user.id === p.id));
        if (!userMatch && p.type !== SenderType.Requester) { users.push(p); }
      });
      users = users.map(user => {
        if (user.type === SenderType.Requester) {
          const customerUser = { ...user } as Customer;
          customerUser.name = this.getCustomerName(customerUser.displayName, customerUser.firstName, customerUser.lastName);

          const transcriptRequester = transcriptUsers.find(u => (u.type === SenderType.Requester));
          if (transcriptRequester && !customerUser.id) { customerUser.id = transcriptRequester.id; }

          return customerUser;
        }

        return user;
      });

      return users;
    }

    static getUpdatedChatStateFromMessages(chatMessages: ChatInteraction[], {state, messagingEnabled, isTransferrable}): {state: ChatState, messagingEnabled: boolean, isTransferrable: boolean}{
      let chatStateUpdates: {state: ChatState, messagingEnabled: boolean, isTransferrable: boolean} = {state, messagingEnabled, isTransferrable};
      const isClosed = chatMessages.find((message: ChatMessage) => message.systemType === SystemMessageType.CustomerExit);
      if (isClosed) {
        chatStateUpdates = {
          state: ChatState.Closed,
          messagingEnabled: false,
          isTransferrable: false
        };
      }
      else{
        const connectionMessages = chatMessages.filter((message: ChatMessage) =>
          message.systemType === SystemMessageType.CustomerReconnect || message.systemType === SystemMessageType.CustomerDisconnect);
          
        if (connectionMessages.length > 0){
          const lastConnectionMessage = connectionMessages[connectionMessages.length - 1];
          switch (lastConnectionMessage.systemType){
            case SystemMessageType.CustomerDisconnect:
              chatStateUpdates = {
                state: ChatState.Disconnected,
                messagingEnabled: false,
                isTransferrable: false
              };
              break;
            case SystemMessageType.CustomerReconnect:
              chatStateUpdates = {
                state: ChatState.Open,
                messagingEnabled: true,
                isTransferrable: true
              };
              break;
          }
        }
      }
      return chatStateUpdates;
    }

    static isCustomerWindowActionMessage(message: ChatInteraction): boolean {
      const systemInfoMessageType = (<SystemChatMessage>message).systemInfoMessageType;
      switch (systemInfoMessageType){
        case SystemInfoMessageType.MaximizedWindow:
        case SystemInfoMessageType.MinimizedWindow:
        case SystemInfoMessageType.SecureDataCollection:
          return true;
        default:
          return false;
      }
    }

    static isPageNavigatedMessage(message: ChatInteraction): boolean {
      return (<SystemChatMessage>message).systemInfoMessageType === SystemInfoMessageType.PageNavigated;
    }

    static isChatActive(customerActivityStatus: CustomerActivityStatus): boolean {
      return customerActivityStatus === CustomerActivityStatus.Active || customerActivityStatus === CustomerActivityStatus.Pinned;
    }

    static deserializeNewChat(data: NewChatResponse, isTransfer: boolean, color: Color, timeService: DayTimeService, userName: string, featureFlags: string[]): Chat {
      const currentTime = timeService.unix();
      const normalizedChatData = {
        chatRequestId: data.chatId,
        queueName: data.queueName,
        queueId: data.queueName,
        siteId: data.siteId,
        scriptTreeId: data.scriptTreeId,
        businessUnitId: data.businessUnitId,
        agentGroupId: data.agentGroupId,
        startTimestamp: currentTime,
        agentResponseMaxSlaInSeconds: data.agentResponseMaxSlaInSeconds,
        agentResponseMidSlaInSeconds: data.agentResponseMidSlaInSeconds,
        autoCloseChat: data.autoCloseChat,
        autoCloseTimer: data.autoCloseTimer,
        nuanceCustomerId: data.nuanceCustomerId,
        businessRule: data.businessRule,
        chatType: data.chatType,
        pageMarkers: data.pageMarkers,
        locale: data.locale,
        autoTransfer: data.autoTransfer,
        autoTransferSeconds: data.autoTransferSeconds,
        autoTransferWarningSeconds: data.autoTransferWarningSeconds,
        isAsync: data.isAsync,
        conversationId: data.conversationId,
        state: ChatState.PendingAcceptance,
        deviceType: data.deviceType,
        isAbandonedAsyncChat: data.isAbandonedAsyncChat
      };
      const chat = new Chat(normalizedChatData);
      chat.channel = new Channel();
      chat.transferred = isTransfer;
      chat.color = color;
      chat.agentAlias = data.agentAlias;

      const agent = new ChatMember();
      agent.type = SenderType.FixAgent;
      chat.users.push(agent);

      const customer = new Customer();
      customer.type = SenderType.Requester;
      if (data?.customer && !data.customer.authenticated && !data.customer.manualConnect) {
        customer.firstName = chat.color.name;
        customer.lastName = 'Guest';
      }
      else {
        customer.firstName = data?.customer?.firstName ? data.customer.firstName : chat.color.name;
        customer.lastName = data?.customer?.lastName ? data.customer.lastName : 'Guest';
        customer.displayName = data?.customer ? data.customer.displayName : null;
      }
      customer.name = ChatHelper.getCustomerName(customer.displayName, customer.firstName, customer.lastName);
      chat.users.push(customer);

      chat.accountNumber = data?.customer?.accountNumber ? data.customer.accountNumber : null;
      chat.authenticated = data?.customer?.authenticated || false;
      chat.isAccountConnected = data?.customer?.manualConnect || false;

      if (isTransfer){
        const transferData = data as TransferInResponse;

        chat.customerGuid = transferData.customerUui?.guid;
        chat.uui = transferData.customerUui?.uui;
        chat.contactMethod = transferData.customerUui?.contactMethod;
        chat.customerDetails = transferData.customerDetails;
        chat.xaTranscriptSessionId = transferData.customerUui?.xaTranscriptSessionId;
        chat.previousEngagementId = transferData.customerUui?.previousEngagementId;
        chat.isReconnectEngagement = Boolean(chat.previousEngagementId);
        const xaTranscriptEndTimestamp = transferData.transcript?.xaTranscriptEndDateTime;
        chat.xaTranscriptEndTimestamp = this.getUnixXaTranscriptEndTimestamp(timeService, xaTranscriptEndTimestamp);

        const language = transferData.customerUui?.language;
        if (language) {
          chat.customerLanguage = ChatHelper.getLanguageFromString(language);
        }

        const channel = new Channel();
        channel.type = transferData?.channel?.channel;
        channel.device = transferData?.channel?.device;
        chat.channel = channel;

        if (channel.type === ChannelType.Sms) {
          chat.selectedPhoneNumber = channel.device ? (<SmsDeviceInformation>channel.device).phoneNumber : null;
        }

        if (transferData.transcript?.interactions && transferData.transcript?.participants) {
          // for transfers
          chat.messages = this.parseTranscriptInteractions(transferData.chatId, transferData.transcript.participants, transferData.transcript.interactions, false);
          transferData.transcript.participants.forEach(participant => {
            if (participant.id !== 'customer') {
              chat.users.push(participant);
            }
          });
        }

        chat.secureDataRequests = transferData.secureDataRequestsData?.secureDataRequests ?? [];
        chat.smartResponsesSent = transferData.smartResponsesData?.smartResponsesSent ?? [];
        chat.agentsFeatureFlags = transferData.agentsFeatureFlagsData?.agentsFeatureFlags;
        chat.events = transferData.transcriptEventsData?.events ?? [];

        if (transferData.xaSuggestionsData){
          chat.xaSuggestionsSessionId = transferData.xaSuggestionsData.xaSuggestionsSessionId;
          const suggestions: Suggestion[] = [];
          transferData.xaSuggestionsData.xaSuggestions.forEach((s: TransferredSuggestion) => {
            // title only needs to be mapped if its being displayed on the UI
            // transferred XaSuggestions do not need to be displayed
            const suggestion: Suggestion = {
              ...s,
              visible: false,
              isLatest: false,
              title: '',
              timesSent: 0
            };
            suggestions.push(suggestion);
          });
          chat.suggestions = suggestions;
        }

        if (chat.xaTranscriptEndTimestamp) {
          // place between xa transcript and agent messages
          const summaryCard = this.createSummaryCard(chat.xaTranscriptEndTimestamp);
          const agentChatBeginIndex = chat.messages.findIndex(m => m.timestamp > chat.xaTranscriptEndTimestamp);
          if (agentChatBeginIndex === -1){
            chat.messages.push(summaryCard);
          }
          else{
            chat.messages.splice(agentChatBeginIndex, 0, summaryCard);
          }
        }
        else if (chat.messages.length){
          const summaryCard = this.createSummaryCard(chat.messages[0].timestamp);
          chat.messages.unshift(summaryCard);
        }
      }

      const agentFeatureFlags: AgentFeatureFlags = {
        agent: userName,
        features: featureFlags
      };
      if (chat.agentsFeatureFlags) {
        chat.agentsFeatureFlags.push(agentFeatureFlags);
      }
      else {
        chat.agentsFeatureFlags = [agentFeatureFlags];
      }

      // default to add the summary card to the messages, if one was not already added due to transfer
      // also after XA messages that get pushed in from XaTranscriptCallback and prepended to the messages
      if (!chat.messages.find(m => m.type === ChatMessageType.SummaryCard)){
        const summaryCard = this.createSummaryCard(chat.startTimestamp);
        chat.messages.push(summaryCard);
      }

      if (featureFlags.includes(FeatureFlags.LanguageTranslator)) {
        const translationConfig = new ChatInteraction();
        translationConfig.type = ChatMessageType.TranslationConfig;
        translationConfig.timestamp = chat.startTimestamp;
        chat.messages.push(translationConfig);
      }

      return chat;
    }

    static createSummaryCard(timestamp: number){
      const summaryCard = new ChatInteraction();
      summaryCard.type = ChatMessageType.SummaryCard;
      summaryCard.timestamp = timestamp;
      return summaryCard;
    }

    static parseTranscriptInteractions(chatId: string, participants: PriorEngagementParticipant[], interactions: ChatTranscriptResponseInteraction[], isHistoric?: boolean): ChatInteraction[] {
      const transcriptInteractions: ChatInteraction[] = [];

      interactions.forEach(i => {
        const participant = participants.find(p => p.id === (i as ChatTranscriptResponseMessageInteraction).senderId);
        const sender = participant ? participant.type : SenderType.System;

        const interaction = this._parseByInteractionType(i, chatId, sender);

        if (interaction){
          interaction.isHistoric = isHistoric ?? false;
          transcriptInteractions.push(interaction);
        }
      });
      return transcriptInteractions;
    }

    private static _parseByInteractionType(i: ChatTranscriptResponseInteraction, chatId: string, sender: SenderType): ChatInteraction{
      switch (i.interactionType) {
        case InteractionTypes.ParticipantExits:
          const participantExitResponse = i as ChatTranscriptResponseParticipantExitInteraction;
          const exitInteraction: ChatMessage = {
            ...participantExitResponse,
            sender: SenderType.System,
            systemType: SystemMessageType.CustomerExit,
            type: ChatMessageType.Message,
            chatId
          };
          return exitInteraction;
        case InteractionTypes.PageNavigated:
          const pageNavigatedResponse = i as ChatTranscriptResponsePageNavigatedInteraction;
          const pageNavigatedInteraction: SystemChatMessage = {
            ...pageNavigatedResponse,
            sender: SenderType.System,
            message: pageNavigatedResponse.url,
            systemType: SystemMessageType.Navigation,
            systemInfoMessageType: SystemInfoMessageType.PageNavigated,
            type: ChatMessageType.Message,
            chatId
          };
          return pageNavigatedInteraction;
        case InteractionTypes.MessageSent:
          const messageResponse = i as ChatTranscriptResponseTextMessageInteraction;
          // Removing _opener_ messages because they only come from the customer test app
          if (messageResponse.senderId !== '_opener_') {
            const messageInteraction: ChatMessage = {
              ...messageResponse,
              type: ChatMessageType.Message,
              sender,
              chatId
            };
            return messageInteraction;
          }
          return null;
        case InteractionTypes.TopicSelection:
          const topicSelectionResponse = i as ChatTranscriptResponseTopicSelectionInteraction;
          const topicSelectionInteraction: ChatMessage = {
            ...topicSelectionResponse,
            type: ChatMessageType.TopicSelection,
            chatId,
            sender,
            message: null
          };
          return topicSelectionInteraction;
        case InteractionTypes.AddressUpdate:
          const addressUpdateResponse = i as ChatTranscriptAddressUpdateInteraction;
          const addressUpdateInteraction: ChatMessage = {
            ...addressUpdateResponse,
            type: ChatMessageType.AddressUpdate,
            chatId,
            sender,
            message: null
          };
          return addressUpdateInteraction;
        case InteractionTypes.CalendarSelection:
          const calendarSelectionResponse = i as ChatTranscriptAddressCalendarSelectionInteraction;
          const calendarSelectionInteraction: ChatMessage = {
            ...calendarSelectionResponse,
            type: ChatMessageType.CalendarSelection,
            chatId,
            sender,
            message: null
          };
          return calendarSelectionInteraction;
        case InteractionTypes.MediaCard:
          const mediaCardResponse = i as ChatTranscriptResponseMediaCardInteraction;
          const mediaCardInteraction: ChatImageMessage = {
            ...mediaCardResponse,
            type: ChatMessageType.MediaCard,
            chatId,
            sender,
            reported: false
          };
          return mediaCardInteraction;
        case InteractionTypes.TransferInformation:
          const transferInformationResponse = i as ChatTranscriptResponseTransferInformationInteraction;
          const transferInformationInteraction: ChatInteraction = {
            ...transferInformationResponse,
            sender: SenderType.System,
            type: ChatMessageType.TransferCard,
            title: transferInformationResponse.transferType === TransferType.AgentDisconnect
              ? TransferSummaryConstants.AgentDisconnectTitle
              : TransferSummaryConstants.TransferTitle,
            chatId
          };
          return transferInformationInteraction;
        case InteractionTypes.InformationMessage:
          const infoMessageResponse = i as ChatTranscriptResponseInformationMessageInteraction;
          const infoMessageInteraction: ChatMessage = {
            ...infoMessageResponse,
            sender: SenderType.System,
            type: ChatMessageType.Message,
            systemType: SystemMessageType.Information,
            chatId
          };
          return infoMessageInteraction;
        case InteractionTypes.XaSuggestion:
          const xaSuggestionResponse = i as ChatTranscriptResponseXaSuggestionInteraction;
          const xaSuggestionInteraction: ChatMessage = {
            ...xaSuggestionResponse,
            sender: SenderType.FixAgent,
            type: ChatMessageType.XaSuggestion,
            message: xaSuggestionResponse.suggestion.body.message,
            xaMessage: xaSuggestionResponse.suggestion.heading,
            chatId
          };
          return xaSuggestionInteraction;
        case InteractionTypes.Datapass:
          const datapassResponse = i as ChatTranscriptResponseDatapassInteraction;
          const datapassInteraction: ChatMessage = {
            ...datapassResponse,
            sender: SenderType.System,
            type: ChatMessageType.Message,
            systemType: SystemMessageType.Datapass,
            message: datapassResponse.data,
            title: datapassResponse.name,
            chatId
          };
          return datapassInteraction;
        case InteractionTypes.AttachmentSent:
          const attachmentResponse = i as ChatTranscriptResponseImageMessageInteraction;
          const attachmentInteraction: ChatImageMessage = {
            ...attachmentResponse,
            type: ChatMessageType.Image,
            sender,
            reported: false,
            chatId
          };
          return attachmentInteraction;
        case InteractionTypes.CustomerReconnected:
          const customerReconnectedResponse = i as ChatTranscriptResponseCustomerReconnectedInteraction;
          const customerReconnectedInteraction: SystemChatMessage = {
            ...customerReconnectedResponse,
            sender: SenderType.System,
            systemType: SystemMessageType.CustomerReconnect,
            type: ChatMessageType.Message,
            chatId
          };
          return customerReconnectedInteraction;
        case InteractionTypes.CustomerDisconnected:
          const customerDisconnectedResponse = i as ChatTranscriptResponseCustomerDisconnectedInteraction;
          const customerDisconnectedInteraction: SystemChatMessage = {
            ...customerDisconnectedResponse,
            sender: SenderType.System,
            systemType: SystemMessageType.CustomerDisconnect,
            type: ChatMessageType.Message,
            chatId,
            message: SystemMessages.CustomerLeft
          };
          return customerDisconnectedInteraction;
        case InteractionTypes.ParticipantEnters: // ChatTranscriptResponseParticipantEnteredInteraction
        default:
          return null;
      }
    }

    static isTextModified(originalText: string, currentText: string){
      return currentText ? !(currentText.replace(/<br>|<*\/?p>|\n|/mg, '').replace(/&amp;/, '&')
          === originalText.replace(/<br>|<*\/?p>|\n|\r/mg, '')) : false;
    }

    static parseAsyncEngagements(getAsyncEngagementsResponse: GetEngagementsResponse, currentChats: Chat[], timeService: DayTimeService): AsyncEngagementTranscript[] {
      const engagements: AsyncEngagementTranscript[] = [];
      const chat = currentChats.find(c => c.chatId === getAsyncEngagementsResponse.chatId);

      const customer = ChatHelper.getCustomerUser(chat);
      getAsyncEngagementsResponse.value.forEach(eng => {
        const interactions = ChatHelper.parseTranscriptInteractions(getAsyncEngagementsResponse.chatId, eng.participants, eng.interactions);
        eng.participants.forEach(p => {
          if (p.type === SenderType.Requester && customer) {
            p.displayName = customer.name;
          }
        });
        const xaTranscriptEndTimestamp = this.getUnixXaTranscriptEndTimestamp(timeService, eng.xaTranscriptEndDateTime);
        const engToAdd = Object.assign(new AsyncEngagementTranscript(), {...eng, interactions, xaTranscriptEndTimestamp});
        delete engToAdd.xaTranscriptEndDateTime;
        engagements.push(engToAdd);
      });

      return engagements;
    }

    //check for the suggested intent already offered
    static isRecommendedIntent(chat: Chat, suggestion: SuggestionBase){
      return chat.recommendedIntents?.some(s => s === suggestion.intent);
    }

    static isIntentNotExists(chat: Chat, suggestion: SuggestionBase){
      return !chat.suggestions?.some(s => s.intent === suggestion.intent);
    }

    static pushNewXaSuggestion(chatSuggestions: Suggestion[], suggestion: Suggestion, responseSuggestions: Suggestion[]) {
      if (!chatSuggestions.some(x => x.intent === suggestion.intent && x.visible)) {
        responseSuggestions.push(suggestion);
        return true;
      }
      return false;
    }

    static shouldIgnoreItgSuggestionOverwrite(suggestionType: SuggestionType, ignoreItgSuggestionOverwrite: boolean): boolean{
      return suggestionType !== SuggestionType.Itg && suggestionType !== SuggestionType.Scheduler && ignoreItgSuggestionOverwrite;
    }

    static parseSuggestions(chat: Chat, payload: AddXaSuggestions, lastCustomerMessage: ChatInteraction, latestActiveItgMessageId: string, responseItgSuggestions: ItgSuggestion[], responseSuggestions: Suggestion[], responseSchedulerSuggestions: SchedulerSuggestion[], ignoreItgSuggestionOverwrite: boolean, hasDriftOccurred: boolean): boolean {
      let isNewSuggestionAdded: boolean = false;
      payload.suggestions.forEach((suggestion: SuggestionBase) => {
        const isLatest = this.shouldIgnoreItgSuggestionOverwrite(suggestion.suggestionType, ignoreItgSuggestionOverwrite) || hasDriftOccurred
                          ? false
                          : !payload.messageId || (<ChatMessage>lastCustomerMessage).messageId === payload.messageId;

        const xaSuggestion: Suggestion = {
          ...suggestion,
          queryId: payload.queryId,
          messageId: payload.messageId,
          isLatest,
          json: payload.json,
          timesSent: 0,
          visible: true
        };

        switch (suggestion.suggestionType)
        {
          case SuggestionType.Itg:
            const itg = suggestion as ItgSuggestion;
            const recommendation = chat.startedItgIntents?.find(i => i === suggestion.intent);
            if (!recommendation){
              let itgSuggestion: ItgSuggestion = {
                ...xaSuggestion,
                ...itg,
              };
              if (itg.isItgStart){
                itgSuggestion = {
                  ...itgSuggestion,
                  isLatest: false,
                  suppressed: true
                };
                isNewSuggestionAdded = false;
              }
              else{
                itgSuggestion = {
                  ...itgSuggestion,
                  isLatest: isLatest || latestActiveItgMessageId === payload.messageId,
                };
                isNewSuggestionAdded = true;
              }
              responseItgSuggestions.push(itgSuggestion);
            }
            break;
          case SuggestionType.Scheduler:
            const scheduler = suggestion as SchedulerSuggestion;
            const schedulerSuggestion: SchedulerSuggestion = {
              ...xaSuggestion,
              ...scheduler
            };
            responseSchedulerSuggestions.push(schedulerSuggestion);
            isNewSuggestionAdded = true;
            break;
          default:
            //suppress suggestion if it is recommended and add if it not already exists
            if (this.isRecommendedIntent(chat, xaSuggestion)){
              if (this.isIntentNotExists(chat, xaSuggestion)){
                xaSuggestion.visible = false;
                xaSuggestion.suppressed = true;
                xaSuggestion.isLatest = false;
                responseSuggestions.push(xaSuggestion);
              }
            }
            else
            {
              isNewSuggestionAdded = this.pushNewXaSuggestion(chat.suggestions, xaSuggestion, responseSuggestions);
            }
            break;
        }
      });
      return isNewSuggestionAdded;
    }

    static getPreviousAsyncEngagement(asyncEngagements: AsyncEngagementTranscript[]): AsyncEngagementTranscript{
      if (!asyncEngagements || asyncEngagements.length === 0){ return null; }
      const engagements = [...asyncEngagements];
      engagements.sort((a, b) => {
        return b.endDate - a.endDate;
      });
      return engagements[0];
    }

    static getLastMessageInAsyncEngagement(asyncEngagement: AsyncEngagementTranscript, senderFilter: (sender: SenderType) => boolean): ChatTranscriptMessage{
      if (!asyncEngagement || !asyncEngagement.interactions || asyncEngagement.interactions.length === 0) { return null; }
      const interactions = asyncEngagement.interactions.filter(i => i.type === ChatMessageType.Message && senderFilter(i.sender));
      this.sortMessagesByTimestamp(interactions);
      return <ChatTranscriptMessage>interactions[interactions.length - 1];
    }

    static isCustomerOrAgentSender(sender: SenderType): boolean{
      return sender === SenderType.Requester || sender === SenderType.FixAgent;
    }

    static isContinuedConversation(isReconnectEngagement: boolean, isAsync: boolean, asyncEngagements: AsyncEngagementTranscript[]): boolean {
      return isReconnectEngagement || (isAsync && asyncEngagements?.length > 0);
    }

    static getUnixXaTranscriptEndTimestamp(timeService: DayTimeService, xaTranscriptEndTimestamp: string): number {
      return xaTranscriptEndTimestamp ? timeService.unix(xaTranscriptEndTimestamp) : null;
    }

    static getItgDriftIntents(suggestions: SuggestionBase[],
      {isUnSupportedContentError, intentResult, intentValue}: {isUnSupportedContentError?: boolean, intentResult: string, intentValue: string}): DriftContent[] {
      const driftIntents: DriftContent[] = [];

      function addDriftIntent(driftIntent: string, driftIntentValue: string){
        if (!driftIntents.find(d => d.contentCode === driftIntent && d.intentValue === driftIntentValue)){
          const driftContent: DriftContent = {
            intentValue: driftIntentValue,
            contentCode: driftIntent
          };
          driftIntents.push(driftContent);
        }
      }

      if (isUnSupportedContentError){
        addDriftIntent(intentResult, intentValue);
      }

      if (Boolean(suggestions?.length)) {
        suggestions.forEach(suggestion => {
          if (!suggestion) { return; }

          switch (suggestion.suggestionType){
            case SuggestionType.Scheduler:
              const schedulerSuggestion = suggestion as SchedulerSuggestion;
              if (!schedulerSuggestion.intent?.startsWith(SchedulerIntents.Appointment)){
                addDriftIntent(schedulerSuggestion.intent, schedulerSuggestion.intentValue);
              }
              break;
            default:
              const itgSuggestion = suggestion as ItgSuggestion;
              if (!itgSuggestion.isItgEnd && itgSuggestion.intent !== ItgIntents.Content){
                addDriftIntent(itgSuggestion.intent, itgSuggestion.intentValue);
              }
              break;
          }
        });
      }

      return driftIntents;
    }

    static removeDisabledItgStartSuggestions(addXaSuggestions: AddXaSuggestions, itgsInProgress: ItgMetadata[]): AddXaSuggestions{
      if (!(addXaSuggestions.suggestions?.length)) {
        return addXaSuggestions;
      }

      addXaSuggestions.suggestions = addXaSuggestions.suggestions.filter(suggestion => {
        if (suggestion && suggestion.suggestionType === SuggestionType.Itg && itgsInProgress) {
          const itgSuggestion = suggestion as ItgSuggestion;
          return !(itgSuggestion.isItgStart && itgsInProgress.some(itg => itgSuggestion.itgIntent?.title === itg.title));
        }

        return true;
      });

      return addXaSuggestions;
    }

    static formatPageMarkerData(currentPageMarkers: string[], newMessage: ChatMessage): string[] {
      if (!newMessage?.message) {
        return null;
      }

      if (newMessage.title === SystemMessages.PageMarker) {
        if (currentPageMarkers?.length) {
          if (newMessage.message !== currentPageMarkers[0]) {
            return [newMessage.message, ...currentPageMarkers];
          }

          return currentPageMarkers;
        }

        return [newMessage.message];
      }

      if (newMessage.title === SystemMessages.PageMarkers) {
        const markers = newMessage.message.split(', ');
        const filteredMarkers = [markers[0]];
        markers.forEach(m => {
          if (m !== filteredMarkers[filteredMarkers.length - 1]) {
            filteredMarkers.push(m);
          }
        });

        if (currentPageMarkers?.length) {
          if (currentPageMarkers[0] === filteredMarkers[filteredMarkers.length - 1]) {
            filteredMarkers.pop();
          }

          return [...filteredMarkers, ...currentPageMarkers];
        }

        return filteredMarkers;
      }

      return null;
    }

    static isChatMessageSelectable(chatMessage: ChatInteraction): boolean {
      return (chatMessage.type === ChatMessageType.Message || chatMessage.type === ChatMessageType.XaSuggestion)
              && chatMessage.sender
              && chatMessage.sender !== SenderType.System
              && !chatMessage.isHistoric;
    }

    static getSelectableMessages(chatMessages: ChatInteraction[], asyncEngagements: AsyncEngagementTranscript[]): ChatInteraction[] {
      let selectableMessages = [];
      asyncEngagements?.forEach(asyncEngagement => {
        const filteredMessages = asyncEngagement.interactions?.filter(message => ChatHelper.isChatMessageSelectable(message));
        if (filteredMessages?.length) {
          selectableMessages = [...selectableMessages, ...filteredMessages];
        }
      });

      //Async engagements may not be in order
      selectableMessages = selectableMessages?.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);

      const currentChatMessages = chatMessages?.filter(message => ChatHelper.isChatMessageSelectable(message));
      if (currentChatMessages?.length) {
        selectableMessages = [...selectableMessages, ...currentChatMessages];
      }

      return selectableMessages;
    }

    static getSuggestionTitle(title: string, suggestionType: SuggestionType){
      if (title) { return title; }
      if (suggestionType === SuggestionType.Xa) { return SuggestionTitles.defaultXa; }
      return '';
    }

    static getDisconnectPreventionMessage(contactMethod: string){
      switch (contactMethod) {
        case 'XA-Mobile':
          return DisconnectPreventionMessageConstants.AppMessage;
        case 'XA-Web':
          return DisconnectPreventionMessageConstants.BrowserMessage;
        default:
          return undefined;
      }
    }

    static isAutoGreetingAllowedContext(uui: string): boolean {
      return Constants.AutoGreetingContextCodes.split(',').includes(UuiHelper.getContextCode(uui));
    }

    static getAutoGreetingMessage(uui: string, contextMessage: string, agentName: string, customerName: string): string {
        const isAllowedContext = this.isAutoGreetingAllowedContext(uui) && contextMessage;
        if (customerName && isAllowedContext){
          return AutoGreetMessageConstants.VerifiedAllowedContextMessage(StringUtils.toTitleCase(customerName), agentName, contextMessage);
        }

        if (!customerName && isAllowedContext){
          return AutoGreetMessageConstants.NotVerifiedAllowedContextMessage(agentName, contextMessage);
        }

        if (customerName && !isAllowedContext){
          return AutoGreetMessageConstants.VerifiedNotAllowedContextMessage(StringUtils.toTitleCase(customerName), agentName);
        }

        if (!customerName && !isAllowedContext){
          return AutoGreetMessageConstants.NotVerifiedNotAllowedContextMessage(agentName);
        }
    }

    static getElapsedUnavailableSeconds(availabilityChange: AvailabilityChange, unavailableTimestamp: number){
      return availabilityChange.available === AvailabilityType.Unavailable
        ? {elapsedUnavailableSeconds: TimestampHelper.getSecondsSinceTimestampInMilliseconds(unavailableTimestamp)}
        : {};
    }

    static isChatPage(pageName: string){
      return pageName === PageNames.home;
    }

    public static getXaTranscriptRemovedInteractions(interactions: ChatInteraction[], xaTranscriptEndTimestamp: number): ChatInteraction[]{
      if (!xaTranscriptEndTimestamp) { return interactions; }
      return interactions?.filter(x => x.timestamp > xaTranscriptEndTimestamp);
    }

    
    public static getAsyncTranscriptMessages(asyncEngagements: AsyncEngagementTranscript[]): ChatInteraction[]{
      const messages = [];
      if (!asyncEngagements || asyncEngagements.length === 0){ return messages; }
      asyncEngagements.forEach(ae => {
        if (ae.interactions?.length > 0){
          const xaTranscriptRemovedInteractions = ChatHelper.getXaTranscriptRemovedInteractions(ae.interactions, ae.xaTranscriptEndTimestamp);
          messages.push(...xaTranscriptRemovedInteractions);
        }
      });
      return messages;
    }

    public static getAllSortedTranscriptMessages(chatMessages: ChatInteraction[], xaTranscriptEndTimestamp: number, asyncEngagements: AsyncEngagementTranscript[], isAsync: boolean, isAsyncEngagementsRequest: boolean): ChatInteraction[]{
      const filteredMessages = chatMessages.filter(m => m.type === ChatMessageType.Message && m.sender !== SenderType.System && !m.isHistoric);
      const currentChatMessages = ChatHelper.getXaTranscriptRemovedInteractions(filteredMessages, xaTranscriptEndTimestamp);
      
      const messages: ChatInteraction[] = [];
      if (isAsync){
        const asyncMessages = ChatHelper.getAsyncTranscriptMessages(asyncEngagements);
        const asyncFilteredMessages = asyncMessages.filter(m => m.type === ChatMessageType.Message && m.sender !== SenderType.System);
        messages.push(...asyncFilteredMessages);
      }
      if (!isAsyncEngagementsRequest){
        messages.push(...currentChatMessages);
      }
      ChatHelper.sortMessagesByTimestamp(messages);
      return messages;
    }

    public static shouldAutoSaveSummary(isUnifiedNotesFlagOn: boolean, summaryData: ChatSummaryData, accountNumber: string, isAsyncPauseAbandoned: boolean, summaryPayload: string): boolean {
      return Boolean(isUnifiedNotesFlagOn
        && accountNumber
        && !summaryData?.isLoading
        && !summaryData?.hasError
        && summaryPayload
        && summaryData?.autoSaveState !== LoadingState.Success
        && summaryData?.autoSaveState !== LoadingState.Pending
        && !isAsyncPauseAbandoned);
    }

    public static shouldBackgroundAutoSaveSummary(isUnifiedNotesFlagOn: boolean, summaryData: ChatSummaryData, accountNumber: string, isAsyncPauseAbandoned: boolean): boolean {
      return Boolean(isUnifiedNotesFlagOn
        && accountNumber
        //Only care if the notes were sent previously or not.
        && summaryData?.autoSaveState !== LoadingState.Success
        && summaryData?.autoSaveState !== LoadingState.Pending
        && !isAsyncPauseAbandoned);
    }

    public static getLanguageFromString(language: string){
      if (!language) { return Language.Unknown; }
      const languages = Object.values(Language);
      const browserVariationLanguages = Object.values(BrowserVariationLanguage);

      let normalizedLanguage = language.toLowerCase();
      // Check for any browser language codes that do not match the translator api language codes
      if (browserVariationLanguages.includes(normalizedLanguage as BrowserVariationLanguage)) {
        return this.browserLanguageToApiLanguage(normalizedLanguage as BrowserVariationLanguage);
      }

      // Check to see if any languages match the exact language code already
      if (this.isSupportedLanguage(normalizedLanguage as Language, languages)) {
        return normalizedLanguage as Language;
      }

      // Check again, but truncate anything after the hyphen
      const hyphenIndex = normalizedLanguage.indexOf('-');
      if (hyphenIndex !== -1) {
        normalizedLanguage = normalizedLanguage.substring(0, hyphenIndex);
        if (this.isSupportedLanguage(normalizedLanguage as Language, languages)) {
          return normalizedLanguage as Language;
        }
      }

      return Language.Unknown;
    }

    private static browserLanguageToApiLanguage(browserLanguage: BrowserVariationLanguage): Language {
      switch (browserLanguage) {
        case BrowserVariationLanguage.Cantonese:
          return Language.Cantonese;
        case BrowserVariationLanguage.ChineseSimplified:
          return Language.ChineseSimplified;
        case BrowserVariationLanguage.ChineseTraditional:
          return Language.ChineseTraditional;
        default:
          return null;
      } 
    }

    private static isSupportedLanguage(language: Language, languagesList: Language[]): boolean {
      return languagesList.includes(language);
    }

    // browserLanguageToApiLanguage should be called already, so no need to worry about BrowserVariationLanguage
    public static isTranslatedChat(translationLanguage: Language, languageTranslatorFeatureFlag: boolean){
      if (!languageTranslatorFeatureFlag || translationLanguage === Language.Unknown || translationLanguage === Language.English) { return false; }
      // Unknown and English are already filtered out before this check hits
      return Object.values(Language).includes(translationLanguage);
    }

    public static shouldTranslateMessages(translationLanguage: Language, languageTranslatorFeatureFlag: boolean, isSettingEnabled: boolean){
      return isSettingEnabled && ChatHelper.isTranslatedChat(translationLanguage, languageTranslatorFeatureFlag);
    }

	public static getDefaultGroupTranslationLanguage(agentGroupId: string, agentGroupIdLanguageLists: AgentGroupIdListsByLanguage) {
		if (agentGroupIdLanguageLists?.[Language.Spanish]?.includes(agentGroupId)) {
			return Language.Spanish;
		}
		return null;
	}

    public static getCustomerQueueLanguage ( customerLanguage: Language, defaultGroupLanguage: Language ) {
      if (customerLanguage && customerLanguage !== Language.English && customerLanguage !== Language.Unknown) {
        return customerLanguage;
	  } else if (defaultGroupLanguage) {
        return defaultGroupLanguage;
      }
	  else if (!customerLanguage) {
        return null;
      } else {
        return Language.English;
      }
    }

    public static isCloseSendTask(taskType: SendTaskType){
      return taskType === SendTaskType.CloseConversation || taskType === SendTaskType.CloseRequest;
    }

    public static getCustomerMessageStatus(hasTranslationError: boolean){
      if (hasTranslationError){
        return ChatMessageStatus.Error;
      }
      return undefined;
    }

    public static isTimestampStackedMessage(message: ChatInteraction, sender: SenderType, prevTimestamp: number) {
      return Boolean(message
        && message.sender === sender
        && message.timestamp - prevTimestamp < AppSettings.stackedMessageThreshold);
    }

    public static doesTraceOrMessageIdMatch(pendingTraceId: string, pendingMessageId: string, chatMessageTraceId: string, chatMessageMessageId?: string): boolean {
      return Boolean((pendingTraceId && chatMessageTraceId && pendingTraceId === chatMessageTraceId) 
        || (pendingMessageId && chatMessageMessageId && pendingMessageId === chatMessageMessageId));
    }
    
    public static shouldUseTranslatedText(isTranslatedChat: boolean, messageSender: SenderType, translatedText: string, hasTranslationError: boolean){
      return (isTranslatedChat && messageSender === SenderType.Requester && !!translatedText && !hasTranslationError);
    }

    public static getSummaryText(chatSummaryData: ChatSummaryData, hasUseRecapFeatureFlag: boolean): string {
      return (hasUseRecapFeatureFlag && chatSummaryData?.recap) ? chatSummaryData.recap : '';
    }

    public static getSelectedChatCustomerName(users: ChatMember[], customerDetails: CustomerDetails){
      if (customerDetails?.firstName && customerDetails?.lastName){
        return `${customerDetails?.firstName} ${customerDetails?.lastName}`;
      }
      else{
        return ChatHelper.getCustomerUserFromUsers(users)?.name;
      }
    }
}
